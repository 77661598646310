<template>
  <svg :height="`${height}px` || '24px'" :width="`${width}px` || '24px'" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 8.66667C2 7.69421 2.38192 6.76157 3.06174 6.07394C3.74156 5.38631 4.66359 5 5.625 5H27.375C28.3364 5 29.2584 5.38631 29.9383 6.07394C30.6181 6.76157 31 7.69421 31 8.66667V17.8333H2V8.66667ZM22.8438 10.5C22.6034 10.5 22.3729 10.5966 22.2029 10.7685C22.033 10.9404 21.9375 11.1736 21.9375 11.4167V13.25C21.9375 13.4931 22.033 13.7263 22.2029 13.8982C22.3729 14.0701 22.6034 14.1667 22.8438 14.1667H26.4688C26.7091 14.1667 26.9396 14.0701 27.1096 13.8982C27.2795 13.7263 27.375 13.4931 27.375 13.25V11.4167C27.375 11.1736 27.2795 10.9404 27.1096 10.7685C26.9396 10.5966 26.7091 10.5 26.4688 10.5H22.8438ZM2 21.5V23.3333C2 24.3058 2.38192 25.2384 3.06174 25.9261C3.74156 26.6137 4.66359 27 5.625 27H27.375C28.3364 27 29.2584 26.6137 29.9383 25.9261C30.6181 25.2384 31 24.3058 31 23.3333V21.5H2Z"
      fill="#F7931E"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#333'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>
